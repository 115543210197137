import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  getverificationRequests,
  updateVerificationRequests,
} from "../../../actions/verificationRequests";
import { useDispatch } from "react-redux";
import { Row, Col } from "antd";
import styles from "./certificates.module.scss";
import { Button } from "../../../component/ui";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const SubmitButton = styled(Button)`
  padding: 0.8rem 1.5rem;
  outline: none;
  cursor: pointer;
  display: inline;
`;

function App(props) {
  const { verificationRequests } = props;
  const dispatch = useDispatch();
  if (!verificationRequests.isLoaded) {
    dispatch(getverificationRequests());
  }
  const path = props.match.params.id;
  const history = useHistory();
  let currRequest = verificationRequests.data.filter((item) => {
    return item.status.toLowerCase() === path;
  });
  const isPending = path === "pending";
  return (
    <div>
      <div className={styles.wrapper}>
        <Row className={styles.nav}>
          <NavLink activeClassName={styles.active} to="/pending">
            PENDING
          </NavLink>
          <NavLink activeClassName={styles.active} to="/approved">
            APPROVED
          </NavLink>
          <NavLink activeClassName={styles.active} to="/rejected">
            REJECTED
          </NavLink>
        </Row>
        <Row className={styles.titleRow}>
          <Col span={12}>NAME</Col>
          <Col span={5}>DATE CREATED</Col>
          <Col span={7}>Actions</Col>
        </Row>

        {currRequest.map((e, count) => {
          var d = new Date(e.time);
          return (
            <Row align="middle" className={styles.dataRow} key={count}>
              <Col span={12}>
                {e.title}
              </Col>
              <Col span={5}>
                {d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear()}
              </Col>

              <Col span={7}>
                {isPending && (
                  <SubmitButton
                    onClick={() => {
                      dispatch(
                        updateVerificationRequests(
                          {
                            request_id: e.id,
                            action: "Approved",
                          },
                          history
                        )
                      );
                    }}
                  >
                    Approve
                  </SubmitButton>
                )}
                {isPending && (
                  <button
                    onClick={() => {
                      dispatch(
                        updateVerificationRequests(
                          {
                            request_id: e.id,
                            action: "Rejected",
                          },
                          history
                        )
                      );
                    }}
                    className={styles.rejectButton}
                  >
                    Reject
                  </button>
                )}
                <NavLink to={`/requests/${e.id}`} className={styles.viewMore}>
                  View Details &nbsp; >
                </NavLink>
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = ({ user, verificationRequests }) => ({
  user,
  verificationRequests,
});

export default connect(mapStateToProps)(App);
