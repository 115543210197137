import React from "react";
import { Redirect } from "react-router";
import { useDispatch } from "react-redux";
import { handleLogOut } from "../../actions/auth";

function Logout(props) {
  const dispatch = useDispatch();
  dispatch(handleLogOut());

  return <Redirect to="/" />;
}

export default Logout;
