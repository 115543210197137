import actionTypes from "../action-types/verificationList";
const defaultState = {
  isLoaded: false,
  data: [],
};

const verificationList = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.setverificationList:
      return action.payload;
    default:
      return state;
  }
};

export default verificationList;
