import axios from "../axios";
import actionTypes from "../action-types/certificates";
import { localStorageKey } from "../const";
import { startLoader, stopLoader } from "./isLoading";
import { getToken } from "./helpers";
import { error } from "../component/ui";

export function setCertificates(data) {
  const payload = {
    isLoaded: true,
    data: data,
  };
  return {
    type: actionTypes.setCertificates,
    payload,
  };
}

export const getCertificates = () => {
  return (dispatch) => {
    dispatch(startLoader());
    let token = localStorage.getItem(localStorageKey);
    if (!token) return;
    token = JSON.parse(token);
    token = token["token"];
    const config = {
      headers: { Authorization: `Token ${token}` },
    };
    axios
      .get(`/certificate/certificate-list`, config)
      .then((res) => {
        dispatch(stopLoader());
        return dispatch(setCertificates(res.data));
      })
      .catch((error) => {
        return "Error";
      });
  };
};

export const downloadCertificates = (id) => {
  return (dispatch) => {
    dispatch(startLoader());
    let token = getToken();
    const data = {
      id: id,
    };
    const config = {
      headers: { Authorization: `Token ${token}` },
    };
    axios
      .post(`/certificate/get-certificate`, data, config)
      .then((res) => {
        window.open(res.data);
        dispatch(stopLoader());
      })
      .catch((err) => {
        error("Opps there was an error. Plz try again !!!!");
        dispatch(stopLoader());
      });
  };
};
