import React, { useEffect } from "react";
import { Spin } from "antd";
import { Row, Col } from "antd";
import { useDispatch } from "react-redux";
import { handleChanneliOAuth } from "../../../actions/auth";
import { connect } from "react-redux";
import { Redirect } from "react-router";

function ChanneliOauthRedict(props) {
  const dispatch = useDispatch();
  const code = new URLSearchParams(props.location.search).get("code");

  useEffect(() => {
    dispatch(handleChanneliOAuth(code));
  }, [code, dispatch]);

  const { user } = props;
  if (user.isLogged) {
    return <Redirect to="/" />;
  }
  return (
    <Row justify="center">
      <Col>
        <Spin style={{ marginTop: "50vh" }} size="large" />
      </Col>
    </Row>
  );
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(ChanneliOauthRedict);
