import actionTypes from "../action-types/certificateListUpload";
import axios from "../axios";
import { setVerificationList } from "./verificationList";
import { success, error } from "../component/ui";
import { startLoader, stopLoader } from "./isLoading";
import { getCertificates } from "./certificates";
import { getToken } from "./helpers";
import { parseCertificateData } from "../helpers/certificateAPI";

export function setCertificateUploadData(payload) {
  return {
    type: actionTypes.setCertificateUploadData,
    payload,
  };
}

export function setCertificateType(payload) {
  return {
    type: actionTypes.setCertificateType,
    payload,
  };
}

export function setCertificateFiles(payload) {
  return {
    type: actionTypes.setCertificateFiles,
    payload,
  };
}
export function submitCertificateData(
  email,
  remarks,
  certificateName,
  data,
  histroy,
  certificateType,
  certificateFiles
) {
  return (dispatch) => {
    dispatch(startLoader());
    let dataToSend = {
      verifier_email: email,
      title: certificateName,
      remarks: remarks,
      certificate_type: certificateType,
      certificates: parseCertificateData(certificateType, data),
      certificateFiles: certificateFiles,
    };
    const token = getToken();
    let config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .post(`/certificate/verification-list`, dataToSend, config)
      .then((res) => {
        var formData = new FormData();
        if (certificateFiles.logo) {
          formData.append("logo", certificateFiles.logo);
        }
        if (certificateFiles.sidebar) {
          formData.append("sidebar", certificateFiles.sidebar);
        }
        formData.append("verification_request", res.data.current_id);
        formData.append("primary_color", certificateFiles.color);

        config = {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "multipart/form-data",
          },
        };
        dispatch(setVerificationList(res.data.verification_request));
        axios
          .post(`/certificate/certificate-files`, formData, config)
          .then((res) => {
            histroy.push("/lists");
            dispatch(stopLoader());
            dispatch(getCertificates());
            success("List uploaded sucessfully !!!");
            return;
          })
          .catch((err) => {
            dispatch(stopLoader());
            error("There was an error in uploading the list, Please try again");
          });
      })
      .catch((err) => {
        dispatch(stopLoader());
        error("There was an error in uploading the list, Please try again");
      });

    return;
  };
}
