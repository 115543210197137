import actionTypes from "../action-types/facultyList";
const defaultState = {
  isLoaded: false,
  data: [],
};

const facultyList = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.setFacultyList:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default facultyList;
