import axios from "../axios";
import { getToken } from "./helpers";
import actionTypes from "../action-types/verificationRequests";
import { startLoader, stopLoader } from "./isLoading";
import { success, error } from "../component/ui";

export function setverificationRequests(data) {
  const payload = {
    isLoaded: true,
    data: data,
  };
  return {
    type: actionTypes.setverificationRequests,
    payload,
  };
}

export const getverificationRequests = () => {
  return (dispatch) => {
    dispatch(startLoader());
    const token = getToken();
    const config = {
      headers: { Authorization: `Token ${token}` },
    };
    axios
      .get(`/certificate/verification-requests`, config)
      .then((res) => {
        dispatch(stopLoader());
        return dispatch(setverificationRequests(res.data));
      })
      .catch((error) => {
        return "Error";
      });
  };
};

export const updateVerificationRequests = (data, history) => {
  return (dispatch) => {
    dispatch(startLoader());
    const token = getToken();
    const config = {
      headers: { Authorization: `Token ${token}` },
    };
    axios
      .put(`/certificate/update-requests`, data, config)
      .then((res) => {
        dispatch(stopLoader());
        success(`Certificates ${data.action} sucessfully !!!`);
        history.push("/");
        return dispatch(setverificationRequests(res.data));
      })
      .catch((err) => {
        dispatch(stopLoader());
        error(
          "There was an error in updating the certificates, Please try again"
        );
      });
  };
};
