import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Row, Col } from "antd";
import styled from "styled-components";
import { textColor, LogoImg, TextColorSpan } from "../../../component/ui";
import "./styles.scss";
import Logo from "../../../static/icons/logo.png";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

const Heading = styled.div`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.0625rem;
  color: ${textColor};

  display: flex;
  align-items: center;
  justify-items: center;
`;

const menu = (
  <Menu>
    <Menu.Item>
      <NavLink to="/logout">Logout</NavLink>
    </Menu.Item>
  </Menu>
);

function App(props) {
  const { user } = props;
  return (
    <Row align="middle" className="navbar">
      <Col span={5}>
        <NavLink to="/">
          <Heading>
            <LogoImg alt="Certification Portal" src={Logo} />
            &nbsp;Certification Portal
          </Heading>
        </NavLink>
      </Col>
      <Col span={15}>{props.children}</Col>
      <Col span={4}>
        <Dropdown
          overlay={menu}
          placement="bottomLeft"
          overlayClassName="dropdownOverlay"
        >
          <span className="navUserInfo">
            <TextColorSpan>{user.data.name}</TextColorSpan>
            <div>
              {user.data.email} <DownOutlined />
            </div>
          </span>
        </Dropdown>
      </Col>
    </Row>
  );
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(App);
