import React from "react";
import { connect } from "react-redux";
import Students from "./students/";
import Faculty from "./faculty/";
import { Route, Switch } from "react-router";

function App(props) {
  const { user } = props;
  const isFaculty = user?.data?.role === "Faculty";
  return (
    <Switch>
      <Route path="/" component={isFaculty ? Faculty : Students} />
    </Switch>
  );
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(App);
