export const css = `<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
body,
html {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

p{
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
}

.content{
  text-align:center;
}

main{
  border: 1px solid {color};
  box-sizing: border-box;
  margin: 18px;
}

.main {
  width: 840px;
  height: 560px;
  display: grid;
  grid-template-columns: 204px auto;
  position: relative;
}

.primary{
  color: {color} !important;
}

.sidebar {
  width: 100%;
  height: 100%;
}

.certify {
  width: 123px;
  height: 84px;
  margin-bottom: 30px;
}

.title {
  margin-top: 100px;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: {color};
}

.text {
  font-size: 14px;
  color: rgba(47, 68, 79, 0.85);
}

.course_name {
  font-weight: 600;
  font-size: 16px;
  color: {color};
}

.name {

  font-style: italic;
  font-weight: bold;
  font-size: 32px;
  
  font-family: 'Merriweather', serif !important;
  color: #2F444F;
  margin-top: 18px;
  margin-bottom: 18px;
}

.proj {
  font-size: 16px;
  color: {color};
}

.passive {
  font-size: 10px;
  color: #4E6069;
  text-decoration: none;
}

.iitrLogo {
  position: absolute;
  width: 219px;
  height: 39px;
  left: 595px;
  top: 18px;
}

.logo{
  width: auto;
  height: 39px;
  position: absolute;
  left: 240px;
  top: 18px;
}

.footer{
    position: absolute;
    bottom : 0;
    right : 0;
    text-align: center;
    left: 204px;
}
</style>`;

export const bottom = `
<div class = "footer">
  <p class="passive issued">Issued on  {today}</p>
  <img src="{certify}" class="certify" />
  <p class="passive">
    This certificate is electronically generated and hence does not require a signature <br />
    To verify this certificate visit:
    <a  class="passive" href = "{link}">  {link} </a>
  </p>
</div>
`;

export const porCerti = `<html>
  <head>
  ${css}
  </head>
  <main>
    <div class="main">
    <div>
      <img class="sidebar" src="{sidebar}" />
    </div>

    <div class = "content">
        <div class="logos">
          <img src = "{logo}" class = "logo" />
          <img src = "{iitrlogo}" class="iitrLogo" />
        </div>

        <p class="title">CERTIFICATE OF APPRECIATION</p>
        <p class="text">This is to certify that</p>
        <p class="name">{name}</p>
        <p class="text"> served as:  <span class="proj">{position}</span></p>
        <p class="text">
          of <b>{group}</b> during <b>{duration}</b>. 
        </p>
        <p class="text">Their contribution is  deeply appreciated.</p>
        ${bottom}
      </div>
    </div>
  </main>
</html>`;

export const memberCerti = `<html>
  <head>
  ${css}
  </head>
  <main>
    <div class="main">
    <div>
      <img class="sidebar" src="{sidebar}" />
    </div>

    <div class = "content">
        <div class="logos">
          <img src = "{logo}" class = "logo" /> 
          <img src = "{iitrlogo}" class="iitrLogo" />
        </div>

        <p class="title">CERTIFICATE OF MEMBERSHIP</p>
        <p class="text">This is to certify that</p>
        <p class="name">{name}</p>
        <p class="text"> served as a member of  <span class="primary">{group}</span> during <b>{duration}</b>. </p>
        <p class="text">Their contribution is  deeply appreciated.</p>
        
        ${bottom}
      </div>
    </div>
  </main>
</html>`;

export const eventWinner = `<html>
  <head>
  ${css}
  </head>
  <main>
    <div class="main">
    <div>
      <img class="sidebar" src="{sidebar}" />
    </div>

    <div class = "content">
        <div class="logos">
          <img src = "{logo}" class = "logo" />
          <img src = "{iitrlogo}" class="iitrLogo" />
        </div>
        <p class="title">CERTIFICATE OF MERIT</p>
        <p class="text">This is to certify that</p>
        <p class="name">{name}</p>
        <p class="text">has secured <b>{position}</b> in: &nbsp; <span class="proj">{event_name}</span></p>
        <p class  ="text">
          {para}
        </p>
        ${bottom}
      </div>
    </div>
  </main>
</html>`;

export const project = `<html>
  <head>
  ${css}
  </head>
  <main>
    <div class="main">
    <div>
      <img class="sidebar" src="{sidebar}" />
    </div>

    <div class = "content">
        <div class="logos">
          <img src = "{logo}" class = "logo" />
          <img src = "{iitrlogo}" class="iitrLogo" />
        </div>

        <p class="title">CERTIFICATE OF PROJECT COMPLETION</p>
        <p class="text">This is to certify that</p>
        <p class="name">{name}</p>
        <p class="text">has successfuly worked on: <span class="proj">{project_name}</span></p>
        <p class="text">
          under the guidance of <b>{group}</b> during <b>{duration}</b>. 
        </p>
        ${bottom}
      </div>
    </div>
  </main>
</html>`;

export const eventPart = `<html>
  <head>
  ${css}
  </head>
  <main>
    <div class="main">
    <div>
      <img class="sidebar" src="{sidebar}" />
    </div>

    <div class = "content">
        <div class="logos">
          <img src = "{logo}" class = "logo" />
          <img src = "{iitrlogo}" class="iitrLogo" />
        </div>

        <p class="title">CERTIFICATE OF PARTICIPATION</p>
        <p class="text">This is to certify that</p>
        <p class="name">{name}</p>
        <p class="text">has successfully participated in:  &nbsp; <span class="proj">{event_name}</span></p>
        <p class  ="text">
          {para}
        </p>
        ${bottom}
      </div>
    </div>
  </main>
</html>`;

export const eventPorCerti = `<html>
  <head>
  ${css}
  </head>
  <main>
    <div class="main">
    <div>
      <img class="sidebar" src="{sidebar}" />
    </div>

    <div class = "content">
        <div class="logos">
          <img src = "{logo}" class = "logo" />
          <img src = "{iitrlogo}" class="iitrLogo" />
        </div>

        <p class="title">CERTIFICATE OF APPRECIATION</p>
        <p class="text">This is to certify that</p>
        <p class="name">{name}</p>
        <p class="text"> served as:  <span class="proj">{position}</span></p>
        <p class="text">
          of <b>{group}</b>  {duration}. 
        </p>
        <p class="text">Their contribution is  deeply appreciated.</p>
        ${bottom}
      </div>
    </div>
  </main>
</html>`;

export const courseCompletion = `<html>
  <head>
  ${css}
  </head>
  <main>
    <div class="main">
    <div>
      <img class="sidebar" src="{sidebar}" />
    </div>

    <div class = "content">
        <div class="logos">
          <img src = "{logo}" class = "logo" />
          <img src = "{iitrlogo}" class="iitrLogo" />
        </div>

        <p class="title">CERTIFICATE OF COMPLETION</p>
        <p class="text">This is to certify that</p>
        <p class="name">{name}</p>
        <p class="text">has successfully completed the course:</p>
        <p class="course_name">{course_name}</p>
        <p class = "text">{para}</p>
        ${bottom}
      </div>
    </div>
  </main>
</html>`;

var domain = "ecertificate.iitr.ac.in";
var certifyLogo =
  "https://ecertificate-static-files.s3.ap-south-1.amazonaws.com/certi/Certificate+stamp.png";
const iitlogo =
  "https://ecertificate-static-files.s3.ap-south-1.amazonaws.com/certi/IIT+R+logo+ENLARGED.png";

var format = require("string-template");

export function getToday() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var month = [];
  month[0] = "January";
  month[1] = "February";
  month[2] = "March";
  month[3] = "April";
  month[4] = "May";
  month[5] = "June";
  month[6] = "July";
  month[7] = "August";
  month[8] = "September";
  month[9] = "October";
  month[10] = "November";
  month[11] = "December";
  var mm = month[today.getMonth()];
  var yyyy = today.getFullYear();
  today = `${dd} ${mm} ${yyyy}`;
  return today;
}

export const sanskritCerti = `<html>
  <head>
  <link rel="stylesheet" href="https://use.typekit.net/oku2xoe.css">
  ${css}
  </head>
  <style>

  .title {
    margin-top: 0px;
    margin-bottom: 0;

  }


  .title{
    margin-bottom: 0;
  }
  .certify{
    margin-bottom: 6px;
  }

  .text{
    margin-bottom:0;
    padding-bottom: 0;
    // line-height: 120%;
    margin-bottom: 0.5em;
  }

  .course_name{
    margin-bottom:0;
  }
  </style>
  <main>
    <div class="main">
    <div>
      <img class="sidebar" src="{sidebar}" />
    </div>

    <div class = "content">
        <div class="logos">
          <img src = "{logo}" class = "logo" />
          <img src = "{iitrlogo}" class="iitrLogo" />
        </div>
        <p class = "text">
        <img 
          src = "https://ecertificate-static-files.s3.ap-south-1.amazonaws.com/hindi1.png" 
          class="title hindi" 
          style = "margin-top: 50px; height:1em"
        >
        </p>
        <b class="title primary">CERTIFICATE OF COMPLETION</b>
        <p class="text">This is to certify that</p>
        <p class="name" style = "margin-top: 0px;margin-bottom: 5px;">{name}</p>
        <p class="text">Registration ID - {username}</p>
        <p class="text">has successfully completed the course:</p>
        <p class ="course_name hindi">
          <img 
            src = "https://ecertificate-static-files.s3.ap-south-1.amazonaws.com/hindi2.png" 
            class = "hindi" 
            style = "height: 21px;margin-bottom:5px"

          >
        </p>
        <p style = "margin-bottom: 1em" class="course_name">Subhashitam Samskritam Course-1 (Spoken part)</p>
        
        <p 
          class = "text hindi"
          style = "margin-bottom: 3em"
        >
          <img 
          src = "https://ecertificate-static-files.s3.ap-south-1.amazonaws.com/hindi3.png" 
          style = "height: 44px;"
          >
        </p>
       
        <p class ="text">
        organized by Sanskrit club, IIT Roorkee in association with Samskrita Bharati
        conducted from 5th July 2020 (Gurupoornima) to 16th July 2020
        </p>
        <p class = "text">
        and has secured: <b>First distinction</b>
        </p>
        ${bottom}
      </div>
    </div>
  </main>
</html>`;

function getSanskritCerti(data) {
  let cert = format(sanskritCerti, {
    name: data.name,
    para: "",
    group: data.meta.group,
    course_name: data.meta.course_name,
    today: getToday(),
    link: `https://${domain}/verification/${data.id}`,
    iitrlogo: iitlogo,
    certify: certifyLogo,
    sidebar: getSideBar(data),
    logo: getLogo(data),
    username: data.username,
    color: data.certificate_files.primary_color,
  });
  return cert;
}

function getSideBar(data) {
  let sidebar = data?.certificate_files?.sidebar;
  sidebar =
    sidebar != null
      ? sidebar
      : "https://ecertificate-static-files.s3.ap-south-1.amazonaws.com/certi/sidebar.png";

  return sidebar;
}

function getLogo(data) {
  let logo = data?.certificate_files?.logo;
  logo =
    logo != null
      ? logo
      : "https://ecertificate-static-files.s3.ap-south-1.amazonaws.com/certi/white.png";
  return logo;
}

function getWinnerCerti(data) {
  let para = "held ";
  if (data.meta.group !== "") {
    para += `during <b>${data.meta.group}</b> &nbsp;`;
  }

  if (data.duration.indexOf("-") === -1) {
    para += `on <b>${data.duration}</b>`;
  } else {
    para += `from <b>${data.duration.replace("-", "to")}</b>`;
  }

  let cert = format(eventWinner, {
    name: data.name,
    position: data.meta.position,
    event_name: data.meta.event_name,
    duration: data.duration,
    today: getToday(),
    para: para,
    link: `https://${domain}/verification/${data.id}`,
    iitrlogo: iitlogo,
    certify: certifyLogo,
    sidebar: getSideBar(data),
    logo: getLogo(data),
    color: data.certificate_files.primary_color,
  });
  return cert;
}

function getEventPartCerti(data) {
  let para = "held ";
  if (data.meta.group !== "") {
    para += `during <b>${data.meta.group}</b> &nbsp;`;
  }

  if (data.duration.indexOf("-") === -1) {
    para += `on <b>${data.duration}</b>`;
  } else {
    para += `from <b>${data.duration.replace("-", "to")}</b>`;
  }

  let cert = format(eventPart, {
    name: data.name,
    event_name: data.meta.event_name,
    duration: data.duration,
    today: getToday(),
    para: para,
    link: `https://${domain}/verification/${data.id}`,
    iitrlogo: iitlogo,
    certify: certifyLogo,
    sidebar: getSideBar(data),
    logo: getLogo(data),
    color: data.certificate_files.primary_color,
  });
  return cert;
}

function getPORCerti(data) {
  let cert = format(porCerti, {
    name: data.name,
    position: data.meta.position,
    duration: data.duration,
    group: data.meta.group,
    today: getToday(),
    link: `https://${domain}/verification/${data.id}`,
    iitrlogo: iitlogo,
    certify: certifyLogo,
    sidebar: getSideBar(data),
    logo: getLogo(data),
    color: data.certificate_files.primary_color,
  });
  return cert;
}

function getProjectCerti(data) {
  let cert = format(project, {
    name: data.name,
    project_name: data.meta.project_name,
    duration: data.duration,
    group: data.meta.group,
    today: getToday(),
    link: `https://${domain}/verification/${data.id}`,
    iitrlogo: iitlogo,
    certify: certifyLogo,
    sidebar: getSideBar(data),
    logo: getLogo(data),
    color: data.certificate_files.primary_color,
  });
  return cert;
}

function getMemberCerti(data) {
  let cert = format(memberCerti, {
    name: data.name,
    position: data.meta.position,
    duration: data.duration,
    group: data.meta.group,
    today: getToday(),
    link: `https://${domain}/verification/${data.id}`,
    iitrlogo: iitlogo,
    certify: certifyLogo,
    sidebar: getSideBar(data),
    logo: getLogo(data),
    color: data.certificate_files.primary_color,
  });
  return cert;
}

function getEventPORCerti(data) {
  let para = "held ";
  if (data.duration.indexOf("-") === -1) {
    para += `on <b>${data.duration}</b>`;
  } else {
    para += `during <b>${data.duration.replace("-", "to")}</b>`;
  }

  let cert = format(eventPorCerti, {
    name: data.name,
    position: data.meta.position,
    duration: para,
    group: data.meta.group,
    today: getToday(),
    link: `https://${domain}/verification/${data.id}`,
    iitrlogo: iitlogo,
    certify: certifyLogo,
    sidebar: getSideBar(data),
    logo: getLogo(data),
    color: data.certificate_files.primary_color,
  });
  return cert;
}

function getCourseCompletionCert(data) {
  let para = `organised by <b>${data.meta.group}</b> `;
  if (data.duration.indexOf("-") === -1) {
    para += `on <b>${data.duration}</b>`;
  } else {
    para += `from <b>${data.duration.replace("-", "to")}</b>`;
  }

  if (data.meta.score !== undefined && data.meta.score !== "") {
    para += `<p class = "text">and has secured: <b>${data.meta.score}</b></p>`;
  }
  let cert = format(courseCompletion, {
    name: data.name,
    para: para,
    group: data.meta.group,
    course_name: data.meta.course_name,
    today: getToday(),
    link: `https://${domain}/verification/${data.id}`,
    iitrlogo: iitlogo,
    certify: certifyLogo,
    sidebar: getSideBar(data),
    logo: getLogo(data),
    color: data.certificate_files.primary_color,
  });
  return cert;
}

export function getHtml(data) {
  const certificateType = parseInt(data.certificate_type_id);
  switch (certificateType) {
    case 1:
      return getWinnerCerti(data);
    case 2:
      return getEventPartCerti(data);
    case 3:
      return getPORCerti(data);
    case 4:
      return getProjectCerti(data);
    case 5:
      return getMemberCerti(data);
    case 6:
      return getEventPORCerti(data);
    case 7:
      return getCourseCompletionCert(data);
    case 8:
      return getSanskritCerti(data);
    default:
      return null;
  }
}
