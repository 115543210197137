import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Table, Modal } from "antd";
import {
  getverificationRequests,
  updateVerificationRequests,
} from "../../../actions/verificationRequests";
import { useDispatch } from "react-redux";
import styles from "./request-details.module.scss";
import Moment from "react-moment";
import { getColumns } from "../dataParser";
import { useHistory } from "react-router-dom";
import { getHtml } from "./html";
import ReactHtmlParser from "react-html-parser";

function App(props) {
  const history = useHistory();
  const [visible, setvisible] = useState(false);
  const { verificationRequests } = props;
  const dispatch = useDispatch();
  if (!verificationRequests.isLoaded) {
    dispatch(getverificationRequests());
  }
  const id = props.match.params.id;
  let currRequest = verificationRequests.data.filter((item) => {
    return item.id === id;
  });
  currRequest = currRequest[0];
  if (currRequest) {
    const isPending = currRequest.status === "Pending";
    let certifictes = currRequest.certificates.map(function (i) {
      return { ...i, ...i.meta };
    });
    let data = certifictes[0];
    data = { ...data, certificate_type_id: currRequest.certificate_type , certificate_files : currRequest.certificate_files[0] };
    const html = getHtml(data);
    let columns = getColumns(currRequest.certificate_type);
    return (
      <div className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          <Row align="middle">
            <Col span={20}>
              <Row className={styles.title}>{currRequest.title}</Row>
              <Row className={styles.membersDetails}>
                Total Members: {currRequest.certificates.length} | Date Created
                : &nbsp;
                <Moment className={styles.certificateDate} format="YYYY/MM/DD">
                  {currRequest.time}
                </Moment>
              </Row>
            </Col>

            {isPending && (
              <Col span={4}>
                <button
                  onClick={() => {
                    dispatch(
                      updateVerificationRequests(
                        {
                          request_id: currRequest.id,
                          action: "Rejected",
                        },
                        history
                      )
                    );
                  }}
                  className={styles.rejectButton}
                >
                  Reject
                </button>
                <button
                  onClick={() => {
                    dispatch(
                      updateVerificationRequests(
                        {
                          request_id: currRequest.id,
                          action: "Approved",
                        },
                        history
                      )
                    );
                  }}
                  className={styles.approveButton}
                >
                  Approve
                </button>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <div
                className={styles.previewCertificste}
                onClick={() => setvisible(true)}
              >
                Preview Certificate
              </div>
            </Col>
          </Row>
          <Table
            className={styles.dataTable}
            dataSource={certifictes}
            columns={columns}
          />
          ;
        </div>

        <Modal
          visible={visible}
          footer={null}
          onCancel={() => setvisible(false)}
          title="Certificate Preview"
          width="930px"
        >
          {ReactHtmlParser(html)}
        </Modal>
      </div>
    );
  }
  return null;
}

const mapStateToProps = ({ user, verificationRequests }) => ({
  user,
  verificationRequests,
});

export default connect(mapStateToProps)(App);
