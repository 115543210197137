import React from "react";
import { Heading, textColor, borderColor } from "../../component/ui";
import Image from "../../static/images/login/main-building.png";
import { channeliRedirect } from "../../const";
import styled from "styled-components";
import styles from "./login.module.scss";
import Logo from "../../static/icons/logo.png";
import InstituteLogo from "../../static/images/login/iitr.png";
import ChanneliIcon from "../../static/icons/channeli.png";
import "font-awesome/css/font-awesome.min.css";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
`;

const Sidebar = styled.div`
  background-image: url(${Image});
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -1px;
`;

const ChanneliLogin = styled.a`
  border: 0.0938rem solid ${borderColor};
  box-sizing: border-box;
  border-radius: 0.25rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;

  color: ${textColor};
  display: inline-block;
  padding: 0.9375rem 1.875rem;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
`;

const ChanneliLogo = styled.img`
  height: 2.5em;
`;

const Main = styled.div`
  padding: 15% 23.4375rem;
`;

export default function Login(props) {
  return (
    <Wrapper>
      <Sidebar />
      <Main>
        <center>
          <img
            className={styles.institute}
            src={InstituteLogo}
            alt="Institute Logo"
          />
          <div className={styles.wrapperLogoTitleSubtitle}>
            <img
              className={styles.logo}
              src={Logo}
              alt="eCertification Portal Logo"
            />
            <div className={styles.title}>Certification Portal</div>
            <div className={styles.subTitle}>
              An online Portal for Students of IIT Roorkee to get verified
              certificates for all their campus activities
            </div>
          </div>
          <div className={styles.headingTag}>
            <Heading> Sign in to your profile.</Heading>
          </div>
          <div className={styles.loginButton}>
            <ChanneliLogin href={channeliRedirect}>
              <ChanneliLogo
                src={ChanneliIcon}
                alt="Channeli Logo"
              />
              &nbsp; Sign in with Channeli
            </ChanneliLogin>
          </div>
        </center>
        <div className={styles.footer}>
          Made with &nbsp;
          <i
            className="fa fa-heart "
            style={{
              color: "#e90606",
            }}
          ></i> 
          &nbsp;by MDG Space and STC
        </div>
      </Main>
    </Wrapper>
  );
}
