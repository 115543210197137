import axios from "../axios";
import { getToken } from "./helpers";
import actionTypes from "../action-types/verificationList";
import { startLoader, stopLoader } from "./isLoading";

export function setVerificationList(data) {
  const payload = {
    isLoaded: true,
    data: data,
  };
  return {
    type: actionTypes.setverificationList,
    payload,
  };
}

export const getVerificationList = () => {
  return (dispatch) => {
    dispatch(startLoader());
    const token = getToken();
    const config = {
      headers: { Authorization: `Token ${token}` },
    };
    axios
      .get(`/certificate/verification-list`, config)
      .then((res) => {
        dispatch(stopLoader());
        return dispatch(setVerificationList(res.data));
      })
      .catch((error) => {
        return "Error";
      });
  };
};
