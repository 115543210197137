import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import styles from "./listUpload.module.scss";
import { Radio, Row, Col } from "antd";
import { NavLink } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { readString } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";
import {
  setCertificateUploadData,
  setCertificateType,
  setCertificateFiles,
} from "../../../../../actions/certificateListUpload";
import { error } from "../../../../../component/ui";
import { useHistory } from "react-router-dom";
import { getHtml } from "./html";
import ReactHtmlParser from "react-html-parser";
import { LinkOutlined, QuestionCircleFilled } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { Typography } from "antd";
import EventParticipantCertificate from "../../../../../static/images/certificates/Event Participant Certificate.png";
import EventPORCertificate from "../../../../../static/images/certificates/Event POR certificate.png";
import EventWinnerCertificate from "../../../../../static/images/certificates/Event Winner Certificate.png";
import GroupPORCertificate from "../../../../../static/images/certificates/Group POR Certificate.png";
import MembershipCertificate from "../../../../../static/images/certificates/Membership Certificate.png";
import ProjectCompletionCertificate from "../../../../../static/images/certificates/Project Completion Certificate.png";
import { dataParser, getColumns } from "../../../dataParser";

function TypeOfCertificate(props) {
  const [visible, setvisible] = useState(false);
  return (
    <div>
      <Radio
        className={styles.radios}
        value={props.value}
        key={props.key}
        checked={false}
      >
        {props.name}
        <QuestionCircleFilled
          onClick={(e) => {
            e.preventDefault();
            setvisible(true);
          }}
          style={{
            marginLeft: "1rem",
            color: "#A9B9D0",
          }}
        />
      </Radio>
      <Modal
        visible={visible}
        title={props.name}
        onCancel={() => setvisible(false)}
        footer={null}
        width={"60rem"}
      >
        <p>
          <Typography.Text>{props.description}</Typography.Text>
        </p>
        <p>
          <Typography.Text strong>List type to use: </Typography.Text>
          <Typography.Text
            underline
            style={{
              color: "#1890ff",
            }}
          >
            {props.list}
          </Typography.Text>
        </p>
        <img
          src={props.image}
          alt={props.name}
          style={{
            width: "100%",
            border: "0.1rem solid #878DA6",
          }}
        />
      </Modal>
    </div>
  );
}

function Step1(props) {
  const [certificateType, setType] = useState();
  const [selected, setselected] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const onChange = (e) => {
    setType(e.target.value);
    setselected(true);
  };

  const onSubmit = (url, message) => {
    if (!selected) {
      error(message);
    } else {
      dispatch(setCertificateType(certificateType));
      history.push(url);
    }
  };

  let typesCertificatesData = [
    {
      key: 1,
      name: "Event Winner Certificate",
      description:
        "Select this for winners of any event that was conducted by your group/fest in campus.",
      list: "Event Winner List",
      image: EventWinnerCertificate,
    },
    {
      key: 2,
      name: "Event Participant Certificate",
      description:
        "Select this for participants of any event that was conducted by your group/fest in campus.",
      list: "Event Participant List",
      image: EventParticipantCertificate,
    },
    {
      key: 3,
      name: "Groups Position of Responsibility Certificate",
      description:
        "Select this for members of your group who have defined PORs.",
      list: "Position of Responsibility list",
      image: GroupPORCertificate,
    },
    {
      key: 4,
      name: "Project Completion Certificate",
      description: "Select this for projects done by your group.",
      list: "Project List",
      image: ProjectCompletionCertificate,
    },
    {
      key: 5,
      name: "Members Certificate",
      description:
        "Select this for members of your group/fest who do not have defined Positions of responsibility.",
      list: "Members List",
      image: MembershipCertificate,
    },
    {
      key: 6,
      name: "Fest/Event Position of Responsibility Certificate",
      description:
        "Select this for students who worked in Fest and have defined PORs.",
      list: "Event/Fest Position of Responsibility List",
      image: EventPORCertificate,
    },
    {
      key: 7,
      name: "Course Completion Certificate",
      description: "Select this for students completed any course",
      list: "Course Completion List",
      image: EventPORCertificate,
    },
    {
      key: 8,
      name: "Sanskrit Club Course Completion Certificate",
      description: "This Certificate is for course Completion",
      list: "Course Completion List",
      image: EventPORCertificate,
    },
  ];

  return (
    <div>
      <div className={styles.main}>
        <div className={styles.title}>Select Certificate type </div>
        <div className={styles.description}>
          Select the type of certificate you want to generate and proceed
        </div>

        <Radio.Group
          size="large"
          onChange={onChange}
          className={styles.radioGroup}
        >
          {typesCertificatesData.map((data) => (
            <TypeOfCertificate {...data} value={data.key} />
          ))}
        </Radio.Group>
      </div>
      <div className={styles.footer}>
        <span
          className={styles.continue}
          onClick={() => {
            onSubmit(`/lists/upload/2`, "Please select certificate type");
          }}
          style={{ marginLeft: "50rem" }}
        >
          CONTINUE
        </span>
      </div>
    </div>
  );
}

function DropCSV(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [files, setFiles] = useState(null);
  const certificate_type = useSelector(
    (state) => state.certificateListUpload.certificateType
  );
  let columns = getColumns(certificate_type)?.map((col) => col.dataIndex);

  const validateFields = (data) => {
    if (data.length === 0) return false;
    const objectFields = Object.keys(data[0]);
    return columns.every((field) => objectFields.includes(field));
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles[0].type !== "text/csv") {
        alert("Only CSV files are allowed. Please upload a valid CSV file.");
        return;
      }

      acceptedFiles = acceptedFiles[0];
      props.selected(true);
      setFiles(`${acceptedFiles.name} uploaded successfully`);
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const results = readString(reader.result);
          results["name"] = acceptedFiles.name;
          let dataToSend = dataParser(results.data, certificate_type);

          if (!validateFields(dataToSend)) {
            alert(
              "CSV file headers don't match the expected fields. Please try again."
            );
            history.push("/lists");
          } else {
            dispatch(setCertificateUploadData(results));
          }
        } catch (error) {
          alert(`Failed to parse the file: ${error.message}`);
        }
      };
      reader.readAsBinaryString(acceptedFiles);
    },
    [dispatch, props, certificate_type, columns, history]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: "text/csv",
  });

  return (
    <span {...getRootProps()}>
      <input {...getInputProps()} />
      <div className={styles.drop}>
        {files ? (
          <div>{files}</div>
        ) : (
          <img src={require("../../../../../static/images/drop.png")} alt="" />
        )}
      </div>
    </span>
  );
}

function Step2(props) {
  const history = useHistory();
  const [selected, setselected] = useState(false);

  const onSubmit = (url, message) => {
    if (!selected) {
      error(message);
    } else {
      history.push(url);
    }
  };

  const certificate_type_id = useSelector(
    (state) => state.certificateListUpload.certificateType
  );
  let link;
  switch (certificate_type_id) {
    case 1:
      link =
        "https://docs.google.com/spreadsheets/d/1C7kPE4o2zZXLstO2CSsHwfNRkyAHAo1yfQR43Fb8CVI/edit#gid=0";

      break;
    case 2:
      link =
        "https://docs.google.com/spreadsheets/d/1iKUwrdg8Yh1K0DCpxnFtyB0nOV1C-hDe0e2Q7ZAg_fQ/edit#gid=0";

      break;
    case 3:
      link =
        "https://docs.google.com/spreadsheets/d/10kKmSLXztBL0wddo6VIl4LeQQ8Ph4GyzuAtcuMoqFVY/edit#gid=0";

      break;
    case 4:
      link =
        "https://docs.google.com/spreadsheets/d/1X7mIjdwJ8iL_9YaDWg-a7zgcjjRLL9B02pPTAAeACns/edit#gid=0";

      break;
    case 5:
      link =
        "https://docs.google.com/spreadsheets/d/1-yUuAoYUWUtl4DldTLeIaf4wjl33wDBPOtyV2OD_YuA/edit#gid=0";

      break;
    case 6:
      link =
        "https://docs.google.com/spreadsheets/d/1kgFuH9-Syt2otV0ZetJ3ypBios_xGSj4Vl3mKJn9IFo/edit#gid=0";

      break;
    default:
      break;
  }

  return (
    <div>
      <div className={styles.main}>
        <div className={styles.title}>Upload Files</div>
        <div className={styles.description}>
          Upload one CSV file with all your details
        </div>
        <a
          className={styles.sheetLink}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          View google sheet Templates <LinkOutlined />
        </a>
        <DropCSV selected={setselected} />
      </div>

      <div className={styles.footer}>
        <NavLink to={`/lists/upload/1`} className={styles.back}>
          BACK
        </NavLink>
        <span
          onClick={() => {
            onSubmit("/lists/upload/3", "Please Upload file");
          }}
          className={styles.continue}
          style={{ marginLeft: "45rem" }}
        >
          CONTINUE
        </span>
      </div>
    </div>
  );
}

function UploadFiles(props) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles = acceptedFiles[0];
      props.setFile(acceptedFiles);
      let reader = new FileReader();
      reader.onloadend = () => {
        props.setFileURL(reader.result);
      };
      reader.readAsDataURL(acceptedFiles);
    },
    [props]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });
  return (
    <span {...getRootProps()}>
      <input {...getInputProps()} />
      <div>{props.children}</div>
    </span>
  );
}

function Step3(props) {
  const history = useHistory();
  const sidebarLink =
    "https://ecertificate-static-files.s3.ap-south-1.amazonaws.com/certi/sidebar.png";
  const [logo, setlogo] = useState();
  const [sidebar, setsidebar] = useState();
  const [logoPreview, setlogoPreview] = useState();
  const [sidebarPreview, setsidebarPreview] = useState(sidebarLink);
  const [color, setcolor] = useState("#1770D0");
  const dispatch = useDispatch();
  const certificate_type_id = useSelector(
    (state) => state.certificateListUpload.certificateType
  );

  const onSubmit = (url) => {
    const data = {
      logo: logo,
      sidebar: sidebar,
      color: color,
    };
    dispatch(setCertificateFiles(data));
    history.push(url);
  };

  let data = {
    logo: logoPreview,
    sidebar: sidebarPreview,
    id: "<id>",
    certificate_type_id: certificate_type_id,
    primary_color: color,
  };

  let cert = getHtml(data);
  return (
    <div>
      <div className={styles.main}>
        <div className={styles.title}>Edit Certificates</div>
        <div className={styles.description}>
          Please modify logo, primary color
        </div>

        <Row className={styles.certiEditor}>
          <Col span={4} className={styles.sidepanel}>
            {
              // <label className={styles.sidepanelLabel}>Primary</label>
              // <input className={styles.colorField} type="field" />
            }

            <label className={styles.sidepanelLabel}>
              {" "}
              Primary Color (Hex)
            </label>
            <input
              className={styles.colorUpload}
              type="text"
              value={color}
              onChange={(e) => {
                setcolor(e.target.value);
              }}
            />

            <label className={styles.sidepanelLabel}> Logo</label>

            {logo ? (
              <span>
                <div className={styles.uploadedSidebarFiles}>{logo.name}</div>
                <div
                  className={styles.removeUploadedFiles}
                  onClick={() => {
                    setlogo(null);
                    setlogoPreview(null);
                  }}
                >
                  Remove
                </div>
              </span>
            ) : (
              <UploadFiles setFile={setlogo} setFileURL={setlogoPreview}>
                <button className={styles.upload_button}>UPLOAD</button>
              </UploadFiles>
            )}

            <label className={styles.sidepanelLabel}> Side Panel</label>

            {sidebar ? (
              <span>
                <div className={styles.uploadedSidebarFiles}>
                  {sidebar.name}
                </div>
                <div
                  className={styles.removeUploadedFiles}
                  onClick={() => {
                    setsidebar(null);
                    setsidebarPreview(sidebarLink);
                  }}
                >
                  Remove
                </div>
              </span>
            ) : (
              <UploadFiles setFileURL={setsidebarPreview} setFile={setsidebar}>
                <button className={styles.upload_button}>UPLOAD</button>
              </UploadFiles>
            )}
          </Col>
          <Col span={20} className={styles.ceritPreview}>
            <div>{ReactHtmlParser(cert)} </div>
          </Col>
        </Row>
      </div>

      <div className={styles.footer}>
        <NavLink to={`/lists/upload/2`} className={styles.back}>
          BACK
        </NavLink>
        <span
          onClick={() => {
            onSubmit("/lists/preview");
          }}
          className={styles.continue}
          style={{ marginLeft: "55rem" }}
        >
          CONTINUE
        </span>
      </div>
    </div>
  );
}

function App(props) {
  return (
    <div
      className={
        props.match.params.id === "3" ? styles.step3Wrapper : styles.wrapper
      }
    >
      <div className={styles.step}>STEP {props.match.params.id} OF 3</div>
      {props.match.params.id === "1" ? <Step1 /> : null}
      {props.match.params.id === "2" ? <Step2 /> : null}
      {props.match.params.id === "3" ? <Step3 /> : null}
    </div>
  );
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(App);
