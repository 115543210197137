import axios from "../axios";
import eMedicalBookletActionTypes from "../action-types/eMedicalBookletActionTypes";
import { getToken } from "./helpers";
import { startLoader, stopLoader } from "./isLoading";

export const setMedicalDatalList = (data) => {
  if (data !== null) {
    data.sort((a, b) => Date.parse(a.IssuedOn) - Date.parse(b.IssuedOn));
  }
  const payload = {
    isLoaded: true,
    data: data,
  };
  return {
    type: eMedicalBookletActionTypes.setMedicalDatalList,
    payload,
  };
}


export const getMedicalData = () =>  {
    return (dispatch) => {
        dispatch(startLoader());
        const token = getToken();
        const config = {
          headers: { Authorization: `Token ${token}` },
        };
        axios
          .get('/medical_booklet/', config)
          .then((res) => {
            dispatch(stopLoader());
            return dispatch(setMedicalDatalList(res.data));
          })
          .catch((error) => {
            console.log(`Error occured with error message: ${error}`);
            return dispatch(setMedicalDatalList(null));
          });
      };
}