import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  background: white;
  display: grid;
  align-items: center;
  justify-items: center;
`;

function App(props) {
  const { isLoading } = props;
  return isLoading ? (
    <Wrapper>
      <img src={require("../static/svg/spin.gif")} alt="" />
    </Wrapper>
  ) : null;
}

const mapStateToProps = ({ isLoading }) => ({
  isLoading,
});

export default connect(mapStateToProps)(App);
