import React from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { Row, Col, Button } from "antd";
import styles from "./icard.module.scss";
import Queries from "./queries";
import { DownloadOutlined, PrinterOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import AutoScale from "react-auto-scale";
import { success, error } from "../../../../../component/ui";
import { getToken } from "../../../../../actions/helpers";
import axios from "../../../../../axios";
import { startLoader, stopLoader } from "../../../../../actions/isLoading";

import Card from "./icard";
function ICardDetails(props) {
  const dispatch = useDispatch();
  const emailIdcard = () => {
    dispatch(startLoader());

    const token = getToken();
    let config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    axios
      .get(`/eid_card/generate-icard`, config)
      .then((res) => {
        success("E ICARD will be emailed to you");
        dispatch(stopLoader());
      })
      .catch((error) => {
        error("Oops there was an issue");
      });
  };

  return (
    <div className={styles.wrapper}>
      <Row className={styles.titleRow}>
        <Col className={styles.title} span={18}>
          e-ID Card
        </Col>
        <Col span={5} className={styles.buttongrid}>
          <Button
            type="primary"
            onClick={() => {
              emailIdcard();
            }}
          >
            Email Me ICard
          </Button>
        </Col>
      </Row>

      <Row>
        <Col span={14} className={styles.icard}>
          <Card baseFontSize="0.7" />
        </Col>
        <Col span={10}>
          <div className={styles.title}>Guidelines</div>
          <ul className={styles.text}>
            <li className={styles.list}>
              A copy of your e-ID Card, either digital or physical, must be
              present with you at all times.
            </li>

            <li>
              Your e-ID card will be used to verify your identity at the entry
              gates of the campus, in hostels, in the mess, in the Student Club,
              and outside of the campus.
            </li>

            <li>
              A printed ID card has to be present with you during examinations.
              Failing to produce a printed ID card in examinations will lead to
              a strict punishment.
            </li>

            <li>
              Any attempt to modify the information in the downloaded e-ID card
              will not be appreciated and will result in strict disciplinary
              action.Your identity can also be verified via the QR code present
              on the e-ID card.
            </li>

            <li>
              In case there is any issue with your e-ID card, use the Raise a
              Query option, and the issue will be resolved as soon as possible.
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}

function App() {
  return (
    <>
      <ICardDetails />
      <Queries />
    </>
  );
}
export default App;
