import authActionTypes from "../action-types/auth";

const defaultState = {
  isLogged : false, 
  error : false,
  errorMessage : "",
  data : {}
}

const user = (state = defaultState, action) => {
  switch (action.type) {
    case authActionTypes.setUserData:
      return action.payload;
    default:
      return state;
  }
};

export default user;
