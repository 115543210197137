import authActionTypes from "../action-types/auth";
import axios from "../axios";
import { localStorageKey } from "../const";
import { startLoader, stopLoader } from "./isLoading";
import {channeliRedirectURL} from "../const"

const defaultState = {
  isLogged: false,
  error: false,
  errorMessage: "",
  data: {},
};

export function setUserData(payload) {
  return {
    type: authActionTypes.setUserData,
    payload,
  };
}

export const handleLogOut = (code) => {
  return (dispatch) => {
    dispatch(startLoader());
    localStorage.removeItem(localStorageKey);
    dispatch(stopLoader());
    return dispatch(setUserData(defaultState));
  };
};

export const handleChanneliOAuth = (code) => {
  return (dispatch) => {
    dispatch(startLoader());
    axios
      .get(`/auth/oauth/channeli?code=${code}&redirect_url=${channeliRedirectURL}`)
      .then((res) => {
        const data = {
          isLogged: true,
          error: false,
          token: res.data.token,
          data: res.data.data,
          errorMessage: "",
        };
        localStorage.setItem(localStorageKey, JSON.stringify(data));
        dispatch(stopLoader());
        return dispatch(setUserData(data));
      })
      .catch((error) => {
        return "Error";
      });
  };
};

export const getDataFromStorage = () => {
  return (dispatch) => {
    dispatch(startLoader());
    let data = localStorage.getItem(localStorageKey);
    if (!data) {
      dispatch(stopLoader());
      return;
    }
    data = JSON.parse(data);
    dispatch(stopLoader());
    return dispatch(setUserData(data));
  };
};
