import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Modal } from "antd";
import styles from "./icard.module.scss";
import certiStyles from "../certificates/certificates.module.scss";
import NoQuery from "../../../../../static/images/no-queries.svg";
import {
  DownloadOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";

import axios from "../../../../../axios";
import { success, error } from "../../../../../component/ui";
import Moment from "react-moment";
import { getToken } from "../../../../../actions/helpers";

function QueriesHeader(props) {
  return (
    <div>
      <Row className={styles.titleRow}>
        <Col className={styles.title} span={18}>
          My Queries
        </Col>
        <Col span={3} className={styles.buttongrid}>
          <div></div>
          <NavLink to="/icard/query">
            <Button icon={<QuestionCircleOutlined />} type="primary">
              Raise a Query
            </Button>
          </NavLink>
        </Col>
      </Row>
    </div>
  );
}

function EmptyQuires(props) {
  return (
    <center>
      <img src={NoQuery} alt="" />
    </center>
  );
}

function QuiresList(props) {
  const data = props.data;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <dic className={styles.QuiresList}>
      <Row className={certiStyles.titleRow}>
        <Col span={2}>S. No.</Col>
        <Col span={4}>Correction Type</Col>
        <Col span={4}>Raised On</Col>
        <Col span={10}>Description</Col>
        <Col span={4}>Status</Col>
      </Row>

      {data.map((e, count) => {
        return (
          <Row className={styles.dataRow} key={count}>
            <Col span={2}>{count + 1}</Col>
            <Col span={4}>{e.type}</Col>
            <Col span={4}>
              <Moment format="YYYY/MM/DD">{e.created_at}</Moment>
            </Col>
            <Col className={styles.QuiresDesc} span={10}>
              {e.description}
            </Col>
            <Col span={4}>
              {e.status === "rejected" ? (
                <>
                  <Button icon={<CloseCircleOutlined />} type="danger">
                    Rejected
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <span onClick={showModal} className={styles.viewRemarkButton}>
                    View Remark
                  </span>
                  <Modal
                    title="Remark"
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                  >
                    {e.remark}
                  </Modal>
                </>
              ) : (
                <Button type="primary">{e.status}</Button>
              )}
            </Col>
          </Row>
        );
      })}
    </dic>
  );
}

function Queries(props) {
  const { user } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    const token = getToken();
    const config = {
      headers: { Authorization: `Token ${token}` },
    };
    axios
      .get(`/eid_card/queries?enroll=${user.data.username}`, config)
      .then((res) => {
        setData(res.data.queries);
      })
      .catch((error) => {
        return "Error";
      });
  }, [user.data.username]);

 
  return (
    <div className={styles.wrapper}>
      <QueriesHeader />
      {data.length === 0 ? <EmptyQuires /> : <QuiresList data={data} />}
    </div>
  );
}

const mapStateToProps = ({ user, icard }) => ({
  user,
  icard,
});

export default connect(mapStateToProps)(Queries);
