import {
  porFields,
  projectsField,
  eventWinnerFields,
  memberField,
  eventPorFields,
  eventPartiFields,
  projectCompletionCertificate,
  sanskritClubCertificate,
} from "./certificateFields";
import dayjs from "dayjs";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function porParser(data) {
  let dataToSend = [];
  for (let i = 1; i < data.length; i++) {
    if (data[i].length === 7) {
      let currData = {
        name: toTitleCase(data[i][0]),
        username: data[i][1],
        email: data[i][2],
        position: data[i][3],
        group: data[i][4],
        duration: `${data[i][5]} - ${data[i][6]}`,
      };
      if (currData.email != null) dataToSend.push(currData);
    }
  }
  return dataToSend;
}

function memberParser(data) {
  let dataToSend = [];
  for (let i = 1; i < data.length; i++) {
    if (data[i].length === 6) {
      let currData = {
        name: toTitleCase(data[i][0]),
        username: data[i][1],
        email: data[i][2],
        group: data[i][3],
        duration: `${data[i][4]} - ${data[i][5]}`,
      };
      if (currData.email != null) dataToSend.push(currData);
    }
  }
  return dataToSend;
}

function projectParser(data) {
  let dataToSend = [];
  for (let i = 1; i < data.length; i++) {
    if (data[i].length === 7) {
      let currData = {
        name: toTitleCase(data[i][0]),
        username: data[i][1],
        email: data[i][2],
        project_name: data[i][3],
        group: data[i][4],
        duration: `${data[i][5]} - ${data[i][6]}`,
      };
      if (currData.email != null) dataToSend.push(currData);
    }
  }
  return dataToSend;
}

function eventWinnerParser(data) {
  let dataToSend = [];

  for (let i = 1; i < data.length; i++) {
    if (data[i].length === 8) {
      let duration;
      if (data[i][7] !== "") {
        duration = `${data[i][6]} - ${data[i][7]}`;
      } else {
        duration = data[i][6];
      }
      let currData = {
        name: toTitleCase(data[i][0]),
        username: data[i][1],
        email: data[i][2],
        event_name: data[i][3],
        position: data[i][4],
        group: data[i][5],
        duration: duration,
      };
      if (currData.email != null) dataToSend.push(currData);
    }
  }
  return dataToSend;
}

function eventPorParser(data) {
  let dataToSend = [];

  for (let i = 1; i < data.length; i++) {
    if (data[i].length === 7) {
      let duration;
      if (data[i][6] !== "") {
        duration = `${data[i][5]} - ${data[i][6]}`;
      } else {
        duration = data[i][5];
      }
      let currData = {
        name: toTitleCase(data[i][0]),
        username: data[i][1],
        email: data[i][2],
        position: data[i][3],
        group: data[i][4],
        duration: duration,
      };
      if (currData.email != null) dataToSend.push(currData);
    }
  }
  return dataToSend;
}

function eventPartParser(data) {
  let dataToSend = [];
  for (let i = 1; i < data.length; i++) {
    if (data[i].length === 7) {
      let duration;
      if (data[i][6] !== "") {
        duration = `${data[i][5]} - ${data[i][6]}`;
      } else {
        duration = data[i][5];
      }

      let currData = {
        name: toTitleCase(data[i][0]),
        username: data[i][1],
        email: data[i][2],
        event_name: data[i][3],
        group: data[i][4],
        duration: duration,
      };
      if (currData.email != null) dataToSend.push(currData);
    }
  }
  return dataToSend;
}

function sanskritClubCompletionCertificate(data) {
  let dataToSend = [];
  for (let i = 1; i < data.length; i++) {
    let currData = {
      username: data[i][0],
      email: data[i][1],
      name: data[i][2],
      score: data[i][3],
    };
    if (currData.email != null) dataToSend.push(currData);
  }
  return dataToSend;
}

function courseCompletionCertificate(data) {
  let dataToSend = [];
  for (let i = 1; i < data.length; i++) {
    if (data[i].length === 8) {
      let duration;
      if (data[i][6] !== "") {
        duration = `${data[i][5]} - ${data[i][6]}`;
      } else {
        duration = data[i][5];
      }

      let currData = {
        name: toTitleCase(data[i][0]),
        username: data[i][1],
        email: data[i][2],
        course_name: data[i][3],
        group: data[i][4],
        duration: duration,
        score: data[i][7],
      };
      if (currData.email != null) dataToSend.push(currData);
    }
  }
  return dataToSend;
}

export function dataParser(data, certificateType) {
  certificateType = parseInt(certificateType);
  switch (certificateType) {
    case 1:
      return eventWinnerParser(data);
    case 2:
      return eventPartParser(data);
    case 3:
      return porParser(data);
    case 4:
      return projectParser(data);
    case 5:
      return memberParser(data);
    case 6:
      return eventPorParser(data);
    case 7:
      return courseCompletionCertificate(data);
    case 8:
      return sanskritClubCompletionCertificate(data);

    default:
      return null;
  }
}

export function getColumns(certificateType) {
  certificateType = parseInt(certificateType);
  switch (certificateType) {
    case 1:
      return eventWinnerFields;
    case 2:
      return eventPartiFields;
    case 3:
      return porFields;
    case 4:
      return projectsField;
    case 5:
      return memberField;
    case 6:
      return eventPorFields;
    case 7:
      return projectCompletionCertificate;
    case 8:
      return sanskritClubCertificate;
    default:
      return null;
  }
}

export function getCertificateType(certificateType) {
  certificateType = parseInt(certificateType);
  switch (certificateType) {
    case 1:
      return "Event Winner Certificate";
    case 2:
      return "Event Participant Certificate";
    case 3:
      return "Position of Responsibility Certificate";
    case 4:
      return "Project Completion Certificate";
    case 5:
      return "Members Certificate";
    case 6:
      return "Event/Fest Position of Responsibility";
    case 7:
      return "Course Completion Certificate";
    case 8:
      return "Sanskrit Club Course Completion Certificate";
    default:
      return null;
  }
}

export function parseDate(date) {
  return dayjs(date).format('DD/MM/YYYY');
}
