import axios from "../axios";
import { startLoader, stopLoader } from "./isLoading";
import { getToken } from "./helpers";
import icardActionTypes from "../action-types/icard";
 

const defaultState = {
  isLoaded: false,
  data: [],
};

export function setIcardData(data) {
  const payload = {
    isLoaded: true,
    data: data,
  };
  return {
    type: icardActionTypes.setIcardData,
    payload,
  };
}

export const getIcardData = (enroll) => {
  return (dispatch) => {
    dispatch(startLoader());
    const token = getToken();
    const config = {
      headers: { Authorization: `Token ${token}` },
    };
    axios
      .get(`/eid_card/status/${enroll}`, config)
      .then((res) => {
        dispatch(stopLoader());
        return dispatch(setIcardData(res.data));
      })
      .catch((error) => {
        return "Error";
      });
  };
};
