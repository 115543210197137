import React, { useState, useEffect } from "react";
import styles from "./vertification.module.scss";
import axios from "../../axios";

function App(props) {
  const [data, setdata] = useState();

  useEffect(() => {
    axios
      .get(`/certificate/verify?id=${props.match.params.id}`)
      .then((res) => {
        setdata(res.data);
      })
      .catch((error) => {
        return "Error";
      });
  }, [props.match.params.id]);

  if (data) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.main}>
          <h1 className={styles.title}>
            This certificate was received by {data.name}
          </h1>
          <p>
            {data.name}’s (Enrollment No : {data.username}) profile is verified
            by IIT Roorkee and certificate is also validated.
          </p>
        </div>
        <div className={styles.imgWrapper}>
          <img src={data.url} className={styles.image} alt="" />
        </div>
      </div>
    );
  }

  return null;
}

export default App;
