import React from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { getCertificates } from "../../../../../actions/certificates";
import { Row, Col, Button } from "antd";
import styles from "./certificates.module.scss";
import { DownloadOutlined } from "@ant-design/icons";
import { downloadCertificates } from "../../../../../actions/certificates";
import { NavLink } from "react-router-dom";
import { getIcardData } from "../../../../../actions/icard";

function Certificates(props) {
  const dispatch = useDispatch();
  const certificates = props.certificates;
  return (
    <div className={styles.wrapper}>
      <Row className={styles.titleRow}>
        <Col span={12}>e-certificates</Col>
        <Col span={4}>DATE CREATED</Col>
        <Col span={4}>VERIFIER</Col>
        <Col span={4}>ACTIONS</Col>
      </Row>

      {certificates.data.map((e, count) => {
        var d = new Date(e.verification_request.time);
        return (
          <Row className={styles.dataRow} key={count}>
            <Col span={12}>{e.verification_request.title}</Col>
            <Col span={4}>
              {d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear()}
            </Col>
            <Col span={4}> {e.verification_request.verifier_name}</Col>
            <Col span={4}>
              {e.verification_request.status === "Approved" ? (
                <span>
                  {/*<Button type="primary">View</Button> &nbsp; &nbsp; */}
                  <Button
                    icon={<DownloadOutlined />}
                    type="primary"
                    onClick={() => {
                      dispatch(downloadCertificates(e.id));
                    }}
                  >
                    Download
                  </Button>
                </span>
              ) : (
                <span>{e.verification_request.status} </span>
              )}
            </Col>
          </Row>
        );
      })}
    </div>
  );
}

function Documents(props) {
  const dispatch = useDispatch();
  const {name, data, path} = props;
  return (
    <div className={styles.wrapper}>
      <Row className={styles.titleRow}>
        <Col span={12}>{name}</Col>
        <Col span={4}>DATE RECEIVED</Col>
        <Col span={4}>ISSUED BY</Col>
        <Col span={4}>ACTIONS</Col>
      </Row>

      <Row className={styles.dataRow}>
        <Col span={12}>{name}</Col>
        <Col span={4}>{data.date}</Col>
        <Col span={4}>{data.verifier_name}</Col>
        <Col span={4}>
          <NavLink to={path}>
            <Button type="Default">View</Button>
          </NavLink>
        </Col>
      </Row>
    </div>
  );
}

function App(props) {
  const dispatch = useDispatch();
  const { certificates, user, icard } = props;

  if (!certificates.isLoaded) {
    dispatch(getCertificates());
  }

  if (!icard.isLoaded) {
    dispatch(getIcardData(user.data.username));
  }

  const isIcardActive = icard?.data?.status?.status === "issued";
  /* API to fetch ICard Issue Date & Verifier */
  const data = {
    date: "14/02/19",
    verifier_name: "Dean of Student Welfare",
  };

  return (
    <>
      {isIcardActive ? <Documents data={data} name='e-ID CARD' path={'/icard'}/> : null}
      <Documents data={data} name='e-Medical Booklet' path={'/emedical'}/>
      <Certificates certificates={certificates} />
    </>
  );
}
const mapStateToProps = ({ certificates, user, icard }) => ({
  certificates,
  user,
  icard,
});

export default connect(mapStateToProps)(App);
