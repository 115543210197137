import { localStorageKey } from "../const";

export function getToken() {
  let token = localStorage.getItem(localStorageKey);
  if (!token) return;
  token = JSON.parse(token);
  return token["token"];
}


