import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import Loading from "./component/loading";

import "./style/index.scss";
import "antd/dist/antd.css";

import Store from "./store";
import App from "./app";

const history = createBrowserHistory();
ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <Router history={history}>
        <App />
          <Loading />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
