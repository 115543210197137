import actionTypes from "../action-types/icard";
const defaultState = {
  isLoaded: false,
  data: [],
};

const icard = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.setIcardData:
      return action.payload;
    default:
      return state;
  }
};

export default icard;

