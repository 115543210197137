import React, { useEffect }  from "react";
import style from './eMedical.module.scss';
import { connect, useDispatch } from "react-redux";
import { Row, Col, Spin} from "antd";
import { parseDate } from '../../../dataParser'
import { medicalDataLimit } from "../../../../../const";
import { getMedicalData } from "../../../../../actions/eMedicalBooklet";


function EMedicalDocument(props) {
    const dispatch = useDispatch();
    const { user, medicalDataList, isLoading } = props;

    useEffect(() => {
        if (medicalDataList.isLoaded === false) {
        dispatch(getMedicalData());
        }
    }, []);


    return (
        <div className={style.emedicalWrapper}>
            {
                medicalDataList.isLoaded ?
                <>
                    <EMedicalDocumentCover user={user} />
                    {
                        Array(Math.ceil(medicalDataList.data.length / medicalDataLimit)).fill(null).map((_, index) => <EmedicalDocumentList user={user} medicalDataList={medicalDataList.data.slice(index * medicalDataLimit, (index + 1) * medicalDataLimit)} />)
                    }
                </>:
                <Row justify="center">
                    <Col>
                        <Spin style={{marginTop: '35vh'}} size="large" />
                    </Col>
                </Row>
            }
        </div>
    );
}

function EMedicalDocumentCover(props) {
    const { user } = props;
    console.log(user);
    return (
        <div className={style.coverPage}>
            <div className={style.emedicalImageRowWrapper}>
                <img
                    src={user.data.student.photo}
                    alt={user.data.name}
                    className={style.emedicalImage}
                />
                <div>
                    <div className={style.emedicalTitle}>{user.data.name}</div>
                    <div>{`${user.data.student.prg_name} ${user.data.student.department}` }</div>
                </div>
            </div>
            <div className={style.emedicalContentWrapper}>
                <div className={style.emedicalTextRowWrapper}>
                    <div className={style.emedicalTextWrapper}>
                        <div className={style.emedicalTitle}>Enrol. No. :</div>
                        <div className={style.emedicalValue}>{user.data.username}</div>
                    </div>
                    <div className={style.emedicalTextWrapper}>
                        <div className={style.emedicalTitle}>D.O.B. :</div>
                        <div className={style.emedicalValue}>{parseDate(user.data.student.dob)}</div>
                    </div>
                </div>
                <div className={style.emedicalTextRowWrapper}>
                    <div className={style.emedicalTextWrapper}>
                        <div className={style.emedicalTitle}>Blood Group :</div>
                        <div className={style.emedicalValue}>{user.data.student.blood_group}</div>
                    </div>
                    <div className={style.emedicalTextWrapper}>
                        <div className={style.emedicalTitle}>Gender :</div>
                        <div className={style.emedicalValue}>{user.data.student.gender}</div>
                    </div>
                </div>
                <div className={style.emedicalTextRowWrapper}>
                    <div className={style.emedicalTextWrapper}>
                        <div className={style.emedicalTitle}>Date of Issue :</div>
                        <div className={style.emedicalValue}>{parseDate(Date())}</div>
                    </div>
                </div>
                <div className={style.emedicalTextRowWrapper}>
                    <div className={style.emedicalTextWrapper}>
                        <div className={style.emedicalTitle}>Date of Validity :</div>
                        <div className={style.emedicalValue}>{user.data.student.validity_upto}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function EmedicalDocumentList(props) {
    const { user, medicalDataList } = props;
    console.log(medicalDataList);
    return (
        <div className={style.listPage}>
            <div className={style.userData}>
                <div className={style.userDataName}>{user.data.name}</div>
                <div className={style.userDataDetail}>{`${user.data.username}, ${user.data.student.prg_name} ${user.data.student.department}`}</div>
            </div>
            <hr />
            <table className={style.emedicalListTable}>
                <tr className={style.emedicalListHeadingContainer}>
                    <th className={style.emedicalDate}>OPD Table</th>
                    <th className={style.emedicalPurpose}>Purpose</th>
                    <th className={style.emedicalDoctor}>Doctor</th>
                    <th className={style.emedicalPrescription}>Prescription</th>
                    <th className={style.emedicalAppointment}>Appointment Id</th>
                </tr>
                {medicalDataList.map((medicalData) => {
                    return (
                        <>
                            <tr className={style.emedicalListContainer}>
                                <td className={style.emedicalDate}>{parseDate(medicalData.OPDDate)}</td>
                                <td className={style.emedicalPurpose}>{medicalData.Complaints}</td>
                                <td className={style.emedicalDoctor}>{medicalData.DoctorCode}</td>
                                <td className={style.emedicalPrescription}>{medicalData.DrugName}</td>
                                <td className={style.emedicalAppointment}>{medicalData.AppointmentID}</td>
                            </tr>
                        </>
                    );
                })}
            </table>
        </div>
    )
}

//TODO: use `useSelector` instead of `mapStateToProps` in all places
const mapStateToProps = ({ user, medicalDataList, isLoading }) => ({
  user,
  medicalDataList,
  isLoading,
});

export default connect(mapStateToProps)(EMedicalDocument)