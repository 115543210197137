import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import styles from "./lists.module.scss";
import { useDispatch } from "react-redux";
import { getVerificationList } from "../../../../../actions/verificationList";
import { UploadOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { useHistory } from "react-router-dom";
import { LinkOutlined } from "@ant-design/icons";

function NotifyModal(props) {
  const history = useHistory();
  return (
    <Modal
      visible={props.visible}
      onCancel={() => props.setvisible(false)}
      title={null}
      onOk={() => {
        history.push("/lists/upload/1");
      }}
    >
      <div className={styles.notifyTitle}>Important!</div>
      <div className={styles.notifyImpText}>
        <strong>
          Proceed only if you have worked directly under the approving professor
          and need a certificate for the same.
        </strong>
        <br />
        <br />
      </div>

      <div className={styles.notifyImpText}>
        If you have worked in a campus group or participated in an event.{" "}
        <span className={styles.notifyContent}>
          Contact group secretary or event organiser.
        </span>
      </div>
    </Modal>
  );
}

function NoList(props) {
  const [visible, setvisible] = useState(false);
  return (
    <div>
      {" "}
      <NotifyModal visible={visible} setvisible={setvisible} />
      <Row align="middle" justify="center" className={styles.container}>
        <Col>
          <img src={require("../../../../../static/svg/noList.svg")} alt="" />
          <div className={styles.listHeading}>No Lists created yet yet!</div>

          <div className={styles.text}>
            1. First write your excel sheet in the required format.
            <br />
            <span className={styles.downloadCSVLink}>
              <a
                href="https://docs.google.com/spreadsheets/d/1ZTwl0ETLYui5gR_wfutSWZ0fUwRpT2t0B6-NZN6yMMo/edit?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
              >
                You can Dowload the template excel files
              </a>
            </span>
            <br />
            <br />
            2. After filling, convert excel in CSV format and upload below
          </div>

          <button
            className={styles.uploadButton}
            style={{ marginTop: "2.75rem" }}
            onClick={() => {
              setvisible(true);
            }}
          >
            <UploadOutlined /> Upload CSV
          </button>
        </Col>
      </Row>
    </div>
  );
}

function App(props) {
  const dispatch = useDispatch();
  const { verificationList } = props;
  useEffect(() => {
    if (!verificationList.isLoaded) {
      dispatch(getVerificationList());
    }
  }, [dispatch, verificationList]);
  const [visible, setvisible] = useState(false);

  if (verificationList.data.length === 0) return <NoList />;
  return (
    <div className={styles.listMain}>
      <NotifyModal visible={visible} setvisible={setvisible} />
      <Row align="middle" className={styles.listTitle}>
        <Col className={styles.listNavBarTitle} span={17}>
          Certificates Lists
        </Col>
        <Col className={styles.downloadExcel}>
          <a
            href="https://docs.google.com/spreadsheets/d/1ZTwl0ETLYui5gR_wfutSWZ0fUwRpT2t0B6-NZN6yMMo/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
          <LinkOutlined /> View Excel Templates
          </a>
        </Col>
        <Col>
          <button
            onClick={() => {
              setvisible(true);
            }}
            className={styles.uploadButton}
          >
            <UploadOutlined /> Upload List
          </button>
        </Col>
      </Row>

      <div className={styles.listTilte}>
        <div>NAME</div>
        <div>DATE CREATED</div>
        <div>STATUS</div>
      </div>

      {verificationList.data.map((e, count) => {
        var d = new Date(e.time);

        return (
          <div className={styles.listData} key={count}>
            <div>{e.title}</div>
            <div>
              {d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear()}
            </div>
            <div>{e.status}</div>
          </div>
        );
      })}
    </div>
  );
}

const mapStateToProps = ({ user, verificationList }) => ({
  user,
  verificationList,
});

export default connect(mapStateToProps)(App);
