import { combineReducers } from "redux";
import user from "./auth";
import certificateListUpload from "./certificateListUpload";
import verificationList from "./verificationList";
import certificates from "./certificates";
import verificationRequests from "./verificationRequests";
import facultyList from "./facultyList";
import isLoading from "./isLoading";
import icard from "./icard";
import medicalDataList from "./eMedicalBooklet";
const allReducers = combineReducers({
  user,
  certificateListUpload,
  verificationList,
  certificates,
  verificationRequests,
  isLoading,
  facultyList,
  icard,
  medicalDataList,
});
export default allReducers;
