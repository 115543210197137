import React from "react";
import { connect } from "react-redux";
import Header from "../components/header";
import { Route, Switch } from "react-router";
import Certificates from "./pages/certificates";
import Lists from "./pages/list";
import ListUploadPreview from "./pages/list/listUploadPreview";
import { NavLink } from "react-router-dom";
import ListUpload from "./pages/list/listUpload";
import ICard from "./pages/icard";
import ICardQuery from "./pages/icard/create-query";
import Card from "./pages/icard/icard";
import EMedicalBooklet from "./pages/eMedicalBooklet";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function ICardPDFView() {
  const print = () => {
    window.html2canvas = html2canvas;
    var doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      // format: [4, 2]
    });

    console.log("dewasgvh j");

    var content = document.getElementById("print");
    console.log("content", content);
    console.log("document.body", document.body);
    doc.html(content, {
      callback: function (doc) {
        console.log("in callback");
        doc.save();
      },
    });
  };

  return (
    <div className="container">
      <div onClick={print()}>Print Order</div>
      <Card baseFontSize="1" />
    </div>
  );
}

function App(props) {
  return (
    <div>
      <Header>
        <NavLink
          className="link"
          exact={true}
          activeClassName="linkActive"
          to="/"
        >
          My Documents
        </NavLink>
        <NavLink
          className="link"
          exact={true}
          activeClassName="linkActive"
          to="/lists"
        >
          Lists
        </NavLink>
      </Header>
      <Switch>
        <Route path="/" exact component={Certificates} />
        <Route path="/lists" exact component={Lists} />
        <Route path="/lists/upload/:id" component={ListUpload} />
        <Route path="/lists/preview" component={ListUploadPreview} />
        <Route path="/icard" exact component={ICard} />
        <Route path="/icard/query" exact component={ICardQuery} />
        <Route path="/icard/card" exact component={ICardPDFView} />
        <Route path="/emedical" exact component={EMedicalBooklet} />
      </Switch>
    </div>
  );
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(App);
