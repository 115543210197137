function porParser(data) {
  let certificates = [];

  for (let i = 0; i < data.length; i++) {
    let currData = {
      name: data[i]["name"],
      username: data[i]["username"],
      email: data[i]["email"],
      duration: data[i]["duration"],
      meta: {
        position: data[i]["position"],
        group: data[i]["group"],
      },
    };
    certificates.push(currData);
  }
  return certificates;
}

function memberParser(data) {
  let certificates = [];

  for (let i = 0; i < data.length; i++) {
    let currData = {
      name: data[i]["name"],
      username: data[i]["username"],
      email: data[i]["email"],
      duration: data[i]["duration"],
      meta: {
        group: data[i]["group"],
      },
    };
    certificates.push(currData);
  }
  return certificates;
}

function eventWinnerParser(data) {
  let dataToSend = [];
  for (let i = 0; i < data.length; i++) {
    let currData = {
      name: data[i]["name"],
      username: data[i]["username"],
      email: data[i]["email"],
      duration: data[i]["duration"],
      meta: {
        event_name: data[i]["event_name"],
        position: data[i]["position"],
        group: data[i]["group"],
      },
    };
    dataToSend.push(currData);
  }
  return dataToSend;
}

function eventPartParser(data) {
  let dataToSend = [];
  for (let i = 0; i < data.length; i++) {
    let currData = {
      name: data[i]["name"],
      username: data[i]["username"],
      email: data[i]["email"],
      duration: data[i]["duration"],
      meta: {
        event_name: data[i]["event_name"],
        group: data[i]["group"],
      },
    };
    dataToSend.push(currData);
  }
  return dataToSend;
}

function projectParser(data) {
  let dataToSend = [];
  for (let i = 0; i < data.length; i++) {
    let currData = {
      name: data[i]["name"],
      username: data[i]["username"],
      email: data[i]["email"],
      duration: data[i]["duration"],
      meta: {
        project_name: data[i]["project_name"],
        group: data[i]["group"],
      },
    };
    dataToSend.push(currData);
  }
  return dataToSend;
}

function eventPorParser(data) {
  let certificates = [];

  for (let i = 0; i < data.length; i++) {
    let currData = {
      name: data[i]["name"],
      username: data[i]["username"],
      email: data[i]["email"],
      duration: data[i]["duration"],
      meta: {
        position: data[i]["position"],
        group: data[i]["group"],
      },
    };
    certificates.push(currData);
  }
  return certificates;
}

function courseCompletionParser(data) {
  let certificates = [];

  for (let i = 0; i < data.length; i++) {
    let currData = {
      name: data[i]["name"],
      username: data[i]["username"],
      email: data[i]["email"],
      duration: data[i]["duration"],
      meta: {
        course_name: data[i]["course_name"],
        group: data[i]["group"],
        score: data[i]["score"],
      },
    };
    certificates.push(currData);
  }
  return certificates;
}

function sanskritClubCompletionCertificate(data) {
  let certificates = [];

  for (let i = 0; i < data.length; i++) {
    let currData = {
      name: data[i]["name"],
      username: data[i]["username"],
      email: data[i]["email"],
      meta: {
        score: data[i]["score"],
      },
    };
    certificates.push(currData);
  }
  return certificates;
}

export function parseCertificateData(certificateType, data) {
  switch (certificateType) {
    case 1:
      return eventWinnerParser(data);
    case 2:
      return eventPartParser(data);
    case 3:
      return porParser(data);
    case 4:
      return projectParser(data);
    case 5:
      return memberParser(data);
    case 6:
      return eventPorParser(data);
    case 7:
      return courseCompletionParser(data);
    case 8:
      return sanskritClubCompletionCertificate(data);
    default:
      return null;
  }
}
