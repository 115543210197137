import React, { useState } from "react";
import styles from "./query.module.scss";
import axios from "../../../../../axios";
import { success, error } from "../../../../../component/ui";
import { getToken } from "../../../../../actions/helpers";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Checkbox, Select, Row, Col, Divider } from "antd";

import { ExclamationCircleFilled, LinkOutlined } from "@ant-design/icons";

const { Option } = Select;

const CreateQuery = () => {
  const history = useHistory();
  const onFinish = (values) => {
    const token = getToken();
    let config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const data = {
      ...values,
      status: "sent",
    };
    axios
      .post(`/eid_card/query`, data, config)
      .then((res) => {
        success("Your Query has been received");
      })
      .catch((err) => {
        error("There was an error in uploading the list, Please try again");
      });
    history.push("/icard");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col span={12}>
          <div className={styles.wrapper}>
            <h1>
              <b>Raise query for e-ID Card</b>
            </h1>

            <br />
            <Form
              name="Query"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              className={styles.form}
            >
              <Form.Item
                label="Query Type"
                name="type"
                rules={[
                  { required: true, message: "Please select your query type" },
                ]}
              >
                <Select placeholder="Query Type">
                  <Option value="reissue">Reissue I Card</Option>
                  <Option value="update_details">Correction of Details</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: "Please input your query!" },
                ]}
              >
                <Input.TextArea />
              </Form.Item>

              <Form.Item name="is_important" valuePropName="checked">
                <Checkbox>
                  Mark this query important (Use only if there is an immediate
                  need for ID card)
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.wrapper}>
            <div className={styles.title}>Correction of Details</div>
            <span>STEPS FOR CORRECTION</span>
            <Divider />

            <div className={styles.text}>
              <ExclamationCircleFilled />
              {"   "} Please read the guidelines carefully before filling out
              the form
            </div>

            <ol className={styles.text}>
              <li>
                Go to{" "}
                <a
                  rel="noopener noreferrer"
                  href="http://acad.iitr.ac.in"
                  target="_blank"
                  style={{ color: "#0000ff" }}
                >
                  acad.iitr.ac.in
                  <LinkOutlined />
                </a>
              </li>
              <li>Login with your Channel-I credentials</li>
              <li>Go to 'Personal Information'</li>
              <li>Correct your details and save your changes</li>
              <li>After submitting, continue filling out the form here</li>
              <li>
                You may mark your query as 'Important' only if you have an
                immediate need of your e-ID card
              </li>
            </ol>

            <div className={styles.text}>
              Fill our details here exactly as you filled them out in the acad
              portal. Any difference in the two entries would result in a
              confusion for the authorites.
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CreateQuery;
