import actionTypes from "../action-types/isLoading";

export function startLoader() {
  const payload = true
  return {
    type: actionTypes.setLoading,
    payload,
  };
}

export function stopLoader() {
    const payload =  false
    return {
      type: actionTypes.setLoading,
      payload,
    };
  }
  
