import actionTypes from "../action-types/certificateListUpload";
const defaultState = {
  data: [],
  isAPISucess: false,
  certificateFiles: {},
  certificateType: 0,
};

const certificateListUpload = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.setCertificateUploadData:
      state.data = action.payload;
      return state;
    case actionTypes.setCertificateType:
      state.certificateType = action.payload;
      return state;
    case actionTypes.setCertificateFiles:
      state.certificateFiles = action.payload;
      return state;

    default:
      return state;
  }
};

export default certificateListUpload;
