import actionTypes from "../action-types/facultyList";
import axios from "../axios";
import { startLoader, stopLoader } from "./isLoading";
import { getToken } from "./helpers";

export function setFacultyList(payload) {
    return {
      type: actionTypes.setFacultyList,
      payload,
    };
  }
  
export function getProfList() {
    return (dispatch) => {
      dispatch(startLoader());
      let token = getToken();
      if (!token) return;
      const config = {
        headers: { Authorization: `Token ${token}` },
      };
      axios
        .get(`/auth/faculty`, config)
        .then((res) => {
          dispatch(stopLoader());
          return dispatch(setFacultyList(res.data));
        })
        .catch((error) => {
          return "Error";
        });
    };
}
