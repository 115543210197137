import { Switch, Route, Redirect } from "react-router-dom";
import React from "react";
import Requests from "./requests";
import RequestsDetails from "./request-details";
import Header from "../components/header";
import { NavLink } from "react-router-dom";
function Routes(props) {
  return (
    <div>
      <Header>
        <NavLink
          className="link"
          exact={true}
          activeClassName="linkActive"
          to="/"
        >
          Requests
        </NavLink>
      </Header>
      <Switch>
        <Route path="/" exact={true}>
          <Redirect to="/pending" />
        </Route>
        <Route path="/:id" exact={true} component={Requests} />
        <Route path="/requests/:id" component={RequestsDetails} />
      </Switch>
    </div>
  );
}

export default Routes;
