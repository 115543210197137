import actionTypes from "../action-types/verificationRequests";
const defaultState = {
  isLoaded: false,
  data: [],
};

const verificationRequests = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.setverificationRequests:
      return action.payload;
    default:
      return state;
  }
};

export default verificationRequests;
