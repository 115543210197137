import actionTypes from "../action-types/isLoading";
const defaultState = false;

const isLoading = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.setLoading:
      return action.payload;
    default:
      return state;
  }
};

export default isLoading;
