import actionTypes from "../action-types/eMedicalBookletActionTypes";
const defaultState = {
  isLoaded: false,
  data: null,
};

const medicalDataList = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.setMedicalDatalList:
      return action.payload;
    default:
      return state;
  }
};

export default medicalDataList;