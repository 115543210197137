import React from "react";
import EMedicalDocument from './eMedical';
import { useDispatch } from "react-redux";
import style from './eMedical.module.scss';
import { getToken } from "../../../../../actions/helpers";
import axios from '../../../../../axios';
import { Button } from "antd";
import { success } from "../../../../../component/ui";
import { startLoader, stopLoader } from "../../../../../actions/isLoading";

function EMedicalPdfView(props) {
  const dispatch = useDispatch();

  const emailMedicalBooklet = () => {
    dispatch(startLoader());

    const token = getToken();
    let config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    axios
      .get(`/medical_booklet/generate_medical_booklet`, config)
      .then((res) => {
        success("Medical Booklet will be mailed to you");
        dispatch(stopLoader());
      })
      .catch((error) => {
        error("Oops there was an issue");
      });
  };
    
  return (
    <div className={style.container}>
      <EMedicalDocument />
      <div className={style.guidelines}>
        <div className={style.titleRow}>
          <div className={style.title}>Guidelines</div>
          <Button
            type="primary"
            onClick={emailMedicalBooklet}
          >
            Email Me Booklet
          </Button>
        </div>
        <ul className={style.instructionList}>
          <li className={style.instructionListItem}>
            A copy of your e-Medical Booklet, either digital or physical, must be present with you at the time of medical checkup and it will contain all the relevant details related to your previous diagnoses.
          </li>

          <li className={style.instructionListItem}>
            Any attempt to modify the information in the downloaded e-Medical Booklet will result in strict disciplinary action.
          </li>
        </ul>
      </div>
    </div>
  );
}

//TODO: use `useSelector` instead of `mapStateToProps` in all places
export default EMedicalPdfView;