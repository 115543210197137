export const porFields = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Enrollment Number",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Duration",
    dataIndex: "duration",
    key: "duration",
  },
  {
    title: "Position of Responsiblity",
    dataIndex: "position",
    key: "position",
  },
  {
    title: "Fest/Group name",
    dataIndex: "group",
    key: "group",
  },
];

export const eventPorFields = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Enrollment Number",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Duration",
    dataIndex: "duration",
    key: "duration",
  },
  {
    title: "Position of Responsiblity",
    dataIndex: "position",
    key: "position",
  },
  {
    title: "Fest/Group name",
    dataIndex: "group",
    key: "group",
  },
];

export const memberField = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Enrollment Number",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Duration",
    dataIndex: "duration",
    key: "duration",
  },
  {
    title: "Fest/Group name",
    dataIndex: "group",
    key: "group",
  },
];

export const projectsField = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Enrollment Number",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Group Name/Supervisor Name",
    dataIndex: "group",
    key: "group",
  },
  {
    title: "Duration",
    dataIndex: "duration",
    key: "duration",
  },
  {
    title: "Project Name",
    dataIndex: "project_name",
    key: "project_name",
  },
];

export const eventWinnerFields = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Enrollment Number",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Fest/Group (Optional)",
    dataIndex: "group",
    key: "group",
  },
  {
    title: "Duration",
    dataIndex: "duration",
    key: "duration",
  },
  {
    title: "Event Name",
    dataIndex: "event_name",
    key: "event_name",
  },
  {
    title: "Position",
    dataIndex: "position",
    key: "position",
  },
];

export const eventPartiFields = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Enrollment Number",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Fest/Group (Optional)",
    dataIndex: "group",
    key: "group",
  },
  {
    title: "Duration",
    dataIndex: "duration",
    key: "duration",
  },
  {
    title: "Event Name",
    dataIndex: "event_name",
    key: "event_name",
  },
];

export const projectCompletionCertificate = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Enrollment Number",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Group Name/Supervisor Name",
    dataIndex: "group",
    key: "group",
  },
  {
    title: "Duration",
    dataIndex: "duration",
    key: "duration",
  },
  {
    title: "Course Name",
    dataIndex: "course_name",
    key: "course_name",
  },
  {
    title: "Distinction (Optional)",
    dataIndex: "score",
    key: "score",
  },
];

export const sanskritClubCertificate = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Roll Number",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Distinction",
    dataIndex: "score",
    key: "score",
  }
];
