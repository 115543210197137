import React, { useState, useCallback, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import styles from "./lists.module.scss";
import { Row, Col, Table } from "antd";
import { NavLink } from "react-router-dom";
import { Modal } from "antd";
import { Formik } from "formik";
import { Button, error } from "../../../../../component/ui";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { submitCertificateData } from "../../../../../actions/certificateListUpload";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { createSelector } from "reselect";

import {
  dataParser,
  getColumns,
  getCertificateType,
} from "../../../dataParser";
import { getProfList } from "../../../../../actions/facultyList";

const SubmitButton = styled(Button)`
  cursor: pointer;
  padding: 0.5rem 1.25rem;
`;

const facultySelector = createSelector(
  (state) => state.facultyList.data,
  (faculty) =>
    faculty.map((faculty) => ({
      label: `${faculty.name} ${
        faculty.faculty ? "-" + faculty.faculty.department : ""
      }`,
      value: faculty.email,
    }))
);

function App(props) {
  const { certificateListUpload } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");
  const currList = certificateListUpload?.data;
  let data = currList ? currList.data : [];
  if (data === undefined) data = [];
  let name = "";

  if (currList.name) {
    name = currList.name.substring(
      currList.name.indexOf("(") + 1,
      currList.name.indexOf(")")
    );
  }
  let dataToSend = dataParser(data, certificateListUpload.certificateType);
  let columns = getColumns(certificateListUpload.certificateType);
  let certiType = getCertificateType(certificateListUpload.certificateType);

  const facultyList = useSelector(facultySelector);

  useEffect(() => {
    dispatch(getProfList());
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    setVisible(true);
  }, []);

  return (
    <span>
      <div className={styles.listUploadPreview}>
        <div className={styles.listUploadPreviewMain}>
          <Row align="middle" className={styles.listUploadRow}>
            <Col span={19} className={styles.listUploadTitle}>
              {name} - {certiType}
            </Col>
            <Col span={2} className={styles.listUploadDelete}>
              <NavLink to="/lists">Delete List </NavLink>
            </Col>
            <Col span={3}>
              <button
                className={styles.listUploadButton}
                onClick={handleSubmit}
              >
                Send for Approval
              </button>
            </Col>
          </Row>
          <Table dataSource={dataToSend} columns={columns} />;
        </div>
      </div>

      <Modal
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        title="Send For Approval"
      >
        <Formik
          initialValues={{ email: "", remarks: "", title: "" }}
          onSubmit={(values, { setSubmitting }) => {
            if (email === "") {
              error("Please Enter Email !!");
            } else if (values.title === "") {
              error("Please enter Title !!");
            } else {
              setVisible(false);
              dispatch(
                submitCertificateData(
                  email,
                  values.remarks,
                  values.title,
                  dataToSend,
                  history,
                  certificateListUpload.certificateType,
                  certificateListUpload.certificateFiles
                )
              );
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <label className={styles.label}>Select Verifier</label>
              <Select
                closeMenuOnSelect={true}
                options={facultyList}
                type="email"
                name="email"
                onChange={(val) => setEmail(val.value)}
                className={styles.select}
              />

              <label className={styles.label}>Certificate Title</label>
              <input
                type="text"
                name = "title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                className={styles.input}
                placeholder = "Eg: Tinkering Lab Members Certificate"
              />

              <label className={styles.label}>Remark (Optional)</label>
              <textarea
                className={styles.input}
                type="remarks"
                name="remarks"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.remarks}
                rows={5}
              />
              <SubmitButton type="submit" disabled={isSubmitting}>
                Submit
              </SubmitButton>
            </form>
          )}
        </Formik>
      </Modal>
    </span>
  );
}

const mapStateToProps = ({ certificateListUpload }) => ({
  certificateListUpload,
});

export default connect(mapStateToProps)(App);
