import React, { useRef } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Modal } from "antd";
import styles from "./card.module.scss";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DoswSign from "../../../../../static/images/doswSign.jpg";
import QRCode from "react-qr-code";
function Front(props) {
  const data = props.data;
  const baseFontSize = props.baseFontSize;
  const qrCodeSize = 170 * baseFontSize;

  const getParsedDOB = (dob) => {
    var date = new Date(dob);
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    return day + "/" + month + "/" + year;
  };

  return (
    <div className={styles.icardWrapper}>
      <Row>
        <Col>
          <img
            src={data.student.photo}
            alt={data.name}
            className={styles.icardImage}
          />
        </Col>

        <Col>
          <div className={styles.icardQR}>
            <QRCode
              size={qrCodeSize}
              value={
                "STUDENT:" +
                data.username +
                ":" +
                data.student.institute_security_key
              }
            />
          </div>
          <div className={styles.validity}>Validity: 31/07/2025</div>
        </Col>
      </Row>
      <div className={styles.ICardContent}>
        <div className={styles.ICardName}>{data.name}</div>
        <div className={styles.ICardBranch}>{data.student.branch}</div>
        <Row>
          <Col span={12} className={styles.icardTitle}>
            Enrol. No. :
          </Col>
          <Col span={12} className={styles.icardTitle}>
            D.O.B. :
          </Col>
        </Row>
        <Row>
          <Col span={12} className={styles.icardText}>
            {data.username}
          </Col>
          <Col span={12} className={styles.icardText}>
            {data.student.dob ? getParsedDOB(data.student.dob) : ""}
          </Col>
        </Row>

        <br />
        <div className={styles.icardTitle}>Phone :</div>
        <div className={styles.icardText}>{data.mobile}</div>

        <Row className={styles.SignWrapper}>
          <Col span={12}>
            <img
              className={styles.studentSignImage}
              src={data.student.sign}
              alt={data.name}
              style={{
                marginTop: "15px",
              }}
            />
            <div className={styles.studentSign}> Student Signature</div>
          </Col>

          <Col span={12}>
            <img src={DoswSign} alt="DOSW Sign" className={styles.doswSign} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

function Back(props) {
  const data = props.data;
  return (
    <div className={styles.icardBackWrapper}>
      <div className={styles.icardTitle}>Father’s Name:</div>
      <div className={styles.icardText}>{data.student.father_name}</div>

      <div className={styles.icardTitle}>Mobile:</div>
      <div className={styles.icardText}>{data.student.father_mobile}</div>

      <br />
      <div className={styles.icardTitle}>Mother’s Name :</div>
      <div className={styles.icardText}>{data.student.mother_name}</div>

      <div className={styles.icardTitle}>Mobile:</div>
      <div className={styles.icardText}>{data.student.mother_mobile}</div>

      <br />

      <div className={styles.icardTitle}>Blood Group :</div>
      <div className={styles.icardText}>{data.student.blood_group}</div>
      <br />
      <div className={styles.icardTitle}>Address :</div>
      <div className={styles.icardText}>{data.student.address}</div>
    </div>
  );
}

function ICard(props) {
  const { user } = props;
  if (!user) return;
  const baseFontSize = props.baseFontSize;
  return (
    <Row style={{ fontSize: `${baseFontSize}rem` }} id="print">
      <Col span={10}>
        <Front data={user.data} baseFontSize={baseFontSize} />
      </Col>
      <Col style={{ paddingLeft: "3em" }} span={10}>
        <Back data={user.data} />
      </Col>
    </Row>
  );
}

const mapStateToProps = ({ user, icard }) => ({
  user,
  icard,
});

export default connect(mapStateToProps)(ICard);
