import styled from "styled-components";
import { message } from 'antd';

export const textColor = "#2F444F";
export const altTextColor = "#FFFFFF";

export const primaryColor = "#1C84EE";
export const secondaryColor = "#A9B9D0";
export const borderColor = "#a9b9d0";

export const TextColorSpan = styled.span`
  color: ${textColor};
`;
export const Button = styled.button`
  color: ${altTextColor};
  display: block;
  text-align: center;
  background: ${primaryColor};
  border-radius: 0.25rem;
  border: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const Heading = styled.div`
  font-weight: bold;
  font-size: 1.9817rem;
  line-height: 2.625rem;
  color: ${textColor};
`;


export const LogoImg = styled.img`
  height: 2em;
`;

export const success = (m) => {
  message.success(m, 5);
};

export const error = (m) => {
  message.error(m, 1);
};