import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import { connect } from "react-redux";
import Login from "./apps/auth/login";
import ChanneliOauthRedict from "./apps/auth/oauth/channeli";
import Student from "./apps/users";
import { getDataFromStorage } from "./actions/auth";
import { useDispatch } from "react-redux";
import Logout from "./apps/auth/logout";
import Verify from "./apps/verification";

function App(props) {
  const { user } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDataFromStorage());
  }, [dispatch]);

  return (
    <Switch>
      <Route path="/verification/:id" component={Verify} exact={true} />
      <Route path="/oauth/channeli" component={ChanneliOauthRedict} />
      <Route path="/logout" component={Logout} />
      <Route path="/" component={user.isLogged ? Student : Login} />
    </Switch>
  );
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(App);
